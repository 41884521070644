/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

enum OrganizationKind {
  BestFriendsAnimalSociety,
  ChildrensCraniofacialAssociation,
  ChildrensDefenseFund,
  GirlsInc,
  GirlsOnTheRun,
  Impact100Global,
  MealsOnWheelsAmerica,
  NationalAllianceOnMentalIllness,
  RonaldMcDonaldHouseCharitiesBayArea,
  WomenForWater,
  WorldVision,
  YoungAudiencesArtsForLearning,
}

export default OrganizationKind;
