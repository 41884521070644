/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import { action } from "mobx";
import { useAsObservableSource } from "mobx-react";
import queryString from "query-string";
import {
  createContext,
  useCallback,
} from "react";
import ShopifyBuy from "shopify-buy";

import OrganizationKind from "../enums/organization-kind";
import ProductKind from "../enums/product-kind";
import { exception } from "../utils/ga";

export interface IShopifyStore {
  lineItems: ShopifyBuy.LineItem[];
  modalVisible: boolean;
  selectedOrganization: OrganizationKind;
  subtotalPrice: number;
  webUrl: string;
  giftNote: string;
}

const STORAGE_KEY = "ls_shopify_checkout_id_v1";

export const ShopifyContext = createContext(undefined);
export let addToShopifyCart: (product: ProductKind, quantity: number) => void;
export let updateShopifyCart: (id: string, quantity: number) => void;
export let showShopifyModal: () => void;
export let hideShopifyModal: () => void;
export let updateOrganization: (organization: OrganizationKind) => void;
export let checkoutRedirect: (giftNote: string) => void;

let shopifyStore: IShopifyStore;
let client: ShopifyBuy.Client;
let checkout: ShopifyBuy.Cart;
let products: ShopifyBuy.Product[];
let productVariants: ShopifyBuy.ProductVariant[];

const initializeShopifyStore = function(): IShopifyStore {
  client = ShopifyBuy.buildClient({
    domain: "shop.lastingsmiles.org",
    storefrontAccessToken: "a00b45a6dc014f09c2252d1d8b694bab",
  });

  let defaultOrganization = OrganizationKind.Impact100Global;

  // Infers the partner from the referrer.
  if (typeof document !== "undefined") {
    if (document.referrer.indexOf("bestfriends.org") >= 0) {
      defaultOrganization = OrganizationKind.BestFriendsAnimalSociety;
    } else if (document.referrer.indexOf("ccakids.org") >= 0) {
      defaultOrganization = OrganizationKind.ChildrensCraniofacialAssociation;
    } else if (document.referrer.indexOf("childrensdefense.org") >= 0) {
      defaultOrganization = OrganizationKind.ChildrensDefenseFund;
    } else if (document.referrer.indexOf("girlsinc.org") >= 0) {
      defaultOrganization = OrganizationKind.GirlsInc;
    } else if (document.referrer.indexOf("girlsontherun.org") >= 0) {
      defaultOrganization = OrganizationKind.GirlsOnTheRun;
    } else if (document.referrer.indexOf("impact100global.org") >= 0) {
      defaultOrganization = OrganizationKind.Impact100Global;
    } else if (document.referrer.indexOf("mealsonwheelsamerica.org") >= 0) {
      defaultOrganization = OrganizationKind.MealsOnWheelsAmerica;
    } else if (document.referrer.indexOf("nami.org") >= 0) {
      defaultOrganization = OrganizationKind.NationalAllianceOnMentalIllness;
    } else if (document.referrer.indexOf("rmhc.org") >= 0) {
      defaultOrganization = OrganizationKind.RonaldMcDonaldHouseCharitiesBayArea;
    } else if (document.referrer.indexOf("womenforwater.com") >= 0) {
      defaultOrganization = OrganizationKind.WomenForWater;
    } else if (document.referrer.indexOf("worldvision.org") >= 0) {
      defaultOrganization = OrganizationKind.WorldVision;
    } else if (document.referrer.indexOf("youngaudiences.org") >= 0) {
      defaultOrganization = OrganizationKind.YoungAudiencesArtsForLearning;
    }
  }

  // Infers the partner from the query string. This can override the referrer.
  if (typeof window !== "undefined") {
    const parsedUrl = queryString.parseUrl(window.location.search);
    const ref = (parsedUrl.query as any).ref;
    switch (ref) {
      case "bf":
        defaultOrganization = OrganizationKind.BestFriendsAnimalSociety;
        break;

      case "cca":
        defaultOrganization = OrganizationKind.ChildrensCraniofacialAssociation;
        break;

      case "cdf":
        defaultOrganization = OrganizationKind.ChildrensDefenseFund;
        break;

      case "girls-inc":
        defaultOrganization = OrganizationKind.GirlsInc;
        break;

      case "gotr":
        defaultOrganization = OrganizationKind.GirlsOnTheRun;
        break;

      case "i100":
        defaultOrganization = OrganizationKind.Impact100Global;
        break;

      case "mow":
        defaultOrganization = OrganizationKind.MealsOnWheelsAmerica;
        break;

      case "nami":
        defaultOrganization = OrganizationKind.NationalAllianceOnMentalIllness;
        break;

      case "rmhc-bay-area":
        defaultOrganization = OrganizationKind.RonaldMcDonaldHouseCharitiesBayArea;
        break;

      case "wfw":
        defaultOrganization = OrganizationKind.WomenForWater;
        break;

      case "wv":
        defaultOrganization = OrganizationKind.WorldVision;
        break;

      case "ya":
        defaultOrganization = OrganizationKind.YoungAudiencesArtsForLearning;
        break;
    }
  }

  // IMPORTANT: This does a lazy fetch of Shopify products and variants.
  initializeShopifyStoreLazy(defaultOrganization);

  return {
    lineItems: [],
    modalVisible: false,
    selectedOrganization: defaultOrganization,
    subtotalPrice: 0,
    webUrl: "",
    giftNote: "",
  };
};

const initializeShopifyStoreLazy = async function(organization: OrganizationKind) {
  try {
    products = await client.product.fetchAll();
    productVariants = products.flatMap((product: ShopifyBuy.Product) =>
      product.variants);

    let checkoutId = "";
    if (typeof sessionStorage !== "undefined") {
      checkoutId = sessionStorage.getItem(STORAGE_KEY);
    }

    // Empty string for SSR and null on the browser if it's the first time.
    if (checkoutId === null || checkoutId === "") {
      await createCheckout(organization);
    } else {
      checkout = await client.checkout.fetch(checkoutId);

      // Checks if the checkout was completed or cancelled, in which case a new one has to be created.
      // tslint:disable-next-line:no-any no-unsafe-any
      if ((checkout as any).completedAt !== null) {
        console.debug("LS: checkout completed.");

        await createCheckout(organization);
      } else {
        console.debug("LS: reused existing cart.");
      }

      shopifyStore.lineItems = checkout.lineItems;
      shopifyStore.subtotalPrice = parseFloat((checkout.subtotalPrice as any).amount);

      // tslint:disable-next-line:no-any no-unsafe-any
      shopifyStore.webUrl = (checkout as any).webUrl;
    }
  } catch (error) {

    // TODO: Lighthouse fails if it sees this error as well as the PWA stops working.
    console.log(error);
    // exception(error, /*fatal=*/true);
  }
};

const createCheckout = async function(organization: OrganizationKind) {
  checkout = await client.checkout.create();

  // TODO: Can the create and attribute update be bundled in one call?
  checkout = await (client.checkout as any)
    .updateAttributes(checkout.id, {
      customAttributes: [{
        key: "ORGANIZATION_V2",
        value: getOrganizationSlug(organization),
      }],
    });

  if (typeof sessionStorage !== "undefined") {
    sessionStorage.setItem(STORAGE_KEY, checkout.id as string);
  }

  console.debug("LS: created new cart.");
};

export const getProductVariantSKU = function(product: ProductKind) {
  switch (product) {
    case ProductKind.BalanceCandle:
      return "balance-candle";
    case ProductKind.BlissCandle:
      return "bliss-candle";
    case ProductKind.CalmCandle:
      return "calm-candle";
    case ProductKind.FocusCandle:
      return "focus-candle";
    case ProductKind.SeaSaltCitrusCandle:
      return "sea-salt-citrus-candle";
    case ProductKind.PassionfruitMangoLipBalm:
      return "passionfruit-mango-lip-balm";
    case ProductKind.PeachBlossomLipBalm:
      return "peach-blossom-lip-balm";
    case ProductKind.PeppermintCremeLipBalm:
      return "peppermint-creme-lip-balm";
    case ProductKind.StrawberryCremeOrganicLipBalm:
      return "strawberry-creme-organic-lip-balm";
    case ProductKind.VanillaBeanOrganicLipBalm:
      return "vanilla-bean-organic-lip-balm";
    case ProductKind.VarietyLipBalm:
      return "variety-lip-balm";
    case ProductKind.VeryCherryLipBalm:
      return "very-cherry-lip-balm";
    case ProductKind.PolishExfoliantLipScrub:
      return "polish-exfoliant-lip-scrub";
  }
};

export const getOrganizationSlug = function(organization: OrganizationKind) {
  switch (organization) {
    case OrganizationKind.BestFriendsAnimalSociety:
      return "best-friends-animal-society";
    case OrganizationKind.ChildrensCraniofacialAssociation:
      return "childrens-craniofacial-association";
    case OrganizationKind.ChildrensDefenseFund:
      return "childrens-defense-fund";
    case OrganizationKind.GirlsInc:
      return "girls-inc";
    case OrganizationKind.GirlsOnTheRun:
      return "girls-on-the-run";
    case OrganizationKind.Impact100Global:
      return "impact100-global";
    case OrganizationKind.MealsOnWheelsAmerica:
      return "meals-on-wheels-america";
    case OrganizationKind.NationalAllianceOnMentalIllness:
      return "national-alliance-on-mental-illness";
    case OrganizationKind.RonaldMcDonaldHouseCharitiesBayArea:
      return "ronald-mcdonald-house-charities-bay-area";
    case OrganizationKind.WomenForWater:
      return "women-for-water";
    case OrganizationKind.WorldVision:
      return "world-vision";
    case OrganizationKind.YoungAudiencesArtsForLearning:
      return "young-audiences-arts-for-learning";
  }
};

export const CustomShopifyContext =
  function({ children }: { children: JSX.Element }) {
    shopifyStore = useAsObservableSource(initializeShopifyStore());

    addToShopifyCart = useCallback(
      action(async (product: ProductKind, quantity: number) => {
        // tslint:disable:no-any no-unsafe-any
        const productVariant = productVariants
          .find((value: ShopifyBuy.ProductVariant) =>
            (value as any).sku === getProductVariantSKU(product));
        // tslint:enable:no-any no-unsafe-any

        checkout =  await client.checkout.addLineItems(checkout.id, [{
          quantity,
          variantId: productVariant.id,

        // tslint:disable-next-line:no-any no-unsafe-any
        } as any]);
        shopifyStore.lineItems = checkout.lineItems;
        shopifyStore.subtotalPrice = parseFloat((checkout.subtotalPrice as any).amount);

        // tslint:disable-next-line:no-any no-unsafe-any
        shopifyStore.webUrl = (checkout as any).webUrl;

        // Show the modal on every add.
        shopifyStore.modalVisible = true;

        console.debug("LS: added to cart.");
      }), []);

    updateShopifyCart = useCallback(
      action(async (id: string, quantity: number) => {

        // tslint:disable-next-line:no-any no-unsafe-any
        checkout = await (client.checkout as any)
          .updateLineItems(checkout.id, [{
            id,
            quantity,
          }]);
        shopifyStore.lineItems = checkout.lineItems;
        shopifyStore.subtotalPrice = parseFloat((checkout.subtotalPrice as any).amount);

        // tslint:disable-next-line:no-any no-unsafe-any
        shopifyStore.webUrl = (checkout as any).webUrl;

        console.debug("LS: updated cart.");
      }), []);

    showShopifyModal = useCallback(
      action(() => {
        shopifyStore.modalVisible = true;
      }), []);

    hideShopifyModal = useCallback(
      action(() => {
        shopifyStore.modalVisible = false;
      }), []);

    updateOrganization = useCallback(
      action(async (organization: OrganizationKind) => {

        // tslint:disable-next-line:no-any no-unsafe-any
        checkout = await (client.checkout as any)
          .updateAttributes(checkout.id, {
            customAttributes: [{
              key: "ORGANIZATION_V2",
              value: getOrganizationSlug(organization),
            }],
          });

        shopifyStore.selectedOrganization = organization;
      }), []);

    checkoutRedirect = useCallback(
      action(async(giftNote: string) => {
        if (giftNote !== "") {
          // tslint:disable-next-line:no-any no-unsafe-any
          checkout = await (client.checkout as any)
            .updateAttributes(checkout.id, {
              note: giftNote,
            });

          shopifyStore.giftNote = giftNote;

          // tslint:disable-next-line:no-any no-unsafe-any
          shopifyStore.webUrl = (checkout as any).webUrl;
        }

        // Do the redirect.
        location.href = shopifyStore.webUrl;
      }), []);

    return (
      <ShopifyContext.Provider value={shopifyStore}>
        {children}
      </ShopifyContext.Provider>
    );
};
